import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/authentication/Login/Login";
import IndexAdministrator from "./pages/administrator/IndexAdministrator/IndexAdministrator";
import IndexCustomer from "./pages/customer/IndexCustomer/IndexCustomer";
import Register from "./pages/authentication/Register/Register";
import PasswordForgotten from "./pages/authentication/PasswordForgotten/PasswordForgotten";
import Users from "./pages/administrator/Users/Users/Users";
import DetailUser from "./pages/administrator/Users/DetailUser/DetailUser";
import EditUser from "./pages/administrator/Users/EditUser/EditUser";
import AddUser from "./pages/administrator/Users/AddUser/AddUser";
import EnergySuppliers from "./pages/administrator/EnergySuppliers/EnergySuppliers/EnergySuppliers";
import DetailEnergySupplier from "./pages/administrator/EnergySuppliers/DetailEnergySupplier/DetailEnergySupplier";
import EditEnergySupplier from "./pages/administrator/EnergySuppliers/EditEnergySupplier/EditEnergySupplier";
import AddEnergySupplier from "./pages/administrator/EnergySuppliers/AddEnergySupplier/AddEnergySupplier";
import Contracts from "./pages/administrator/Contracts/Contracts/Contracts";
import DetailContract from "./pages/administrator/Contracts/DetailContract/DetailContract";
import EditContract from "./pages/administrator/Contracts/EditContract/EditContract";
import AddContract from "./pages/administrator/Contracts/AddContract/AddContract";
import FilesCustomer from "./pages/customer/Files/FilesCustomer";
import ChangeEnergyContract from "./pages/administrator/ChangeEnergyContract/ChangeEnergyContract/ChangeEnergyContract";
import SolarPanelsApplication from "./pages/administrator/SolarPanelsApplication/SolarPanelsApplication/SolarPanelsApplication";
import SolarPanelsApplicationCustomer from "./pages/customer/SolarPanelsApplicationCustomer/SolarPanelsApplicationCustomer/SolarPanelsApplicationCustomer";
import SolarPanelsApplicationDetail from "./pages/administrator/SolarPanelsApplication/SolarPanelsApplicationDetail/SolarPanelsApplicationDetail";
import SolarPanelCleaningApplicationCustomer from "./pages/customer/SolarPanelCleaningApplicationCustomer/SolarPanelCleaningApplicationCustomer";
import BoilerMaintenanceApplicationCustomer from "./pages/customer/BoilerMaintenanceApplicationCustomer/BoilerMaintenanceApplicationCustomer";
import BoilerMaintenanceApplication from "./pages/administrator/BoilerMaintenanceApplication/BoilerMaintenanceApplication/BoilerMaintenanceApplication";
import SolarPanelCleaningApplication from "./pages/administrator/SolarPanelCleaningApplication/SolarPanelCleaningApplication/SolarPanelCleaningApplication";
import SolarPanelCleaningApplicationDetail from "./pages/administrator/SolarPanelCleaningApplication/SolarPanelCleaningApplicationDetail/SolarPanelCleaningApplicationDetail";
import BoilerMaintenanceApplicationDetail from "./pages/administrator/BoilerMaintenanceApplication/BoilerMaintenanceApplicationDetail/BoilerMaintenanceApplicationDetail";
import DetailContractInstance from "./pages/administrator/Contracts/ContractInstances/DetailContractInstance/DetailContractInstance";
import Contact from "./pages/customer/Contact/Contact";
import AddContractGas from "./pages/administrator/Contracts/AddContract/AddContractGas";
import EditContractGas from "./pages/administrator/Contracts/EditContract/EditContractGas";
import MyContracts from "./pages/customer/MyContracts/MyContracts/MyContracts";
import MyContractsDetail from "./pages/customer/MyContracts/MyContractsDetail/MyContractsDetail";
import Partners from "./pages/customer/Partners/Partners";
import Quotes from "./pages/customer/Quotes/Quotes";
import Services from "./pages/customer/Services/Services";
import ChangeEnergyContractCustomer from "./pages/customer/ChangeEnergyContractCustomer/ChangeEnergyContractCustomer/ChangeEnergyContractCustomer";
import ElectricityContractInput from "./pages/customer/ChangeEnergyContractCustomer/ElectricityContractInput/ElectricityContractInput";
import GasContractInput from "./pages/customer/ChangeEnergyContractCustomer/GasContractInput/GasContractInput";
import CombiContractInput from "./pages/customer/ChangeEnergyContractCustomer/CombiContractInput/CombiContractInput";
import DocumentSign from "./pages/customer/ChangeEnergyContractCustomer/ChangeEnergyContractCustomer/DocumentSign/DocumentSign";
import Advice from "./pages/customer/Advice/Advice";
import MyProfile from "./pages/customer/MyProfile/MyProfile";
import DocumentCommit from "./pages/customer/ChangeEnergyContractCustomer/ChangeEnergyContractCustomer/DocumentCommit/DocumentCommit";
import ChangeEnergyContractDetail from "./pages/administrator/ChangeEnergyContract/ChangeEnergyContractDetail/ChangeEnergyContractDetail";
import SolarPanelsArticle from "./pages/customer/Advice/Articles/Solarpanels/SolarpanelsArticle";
import SolarPanelsCleaningArticle from "./pages/customer/Advice/Articles/SolarPanelsCleaning/SolarpanelsCleaningArticle";
import BoilerMaintenanceArticle from "./pages/customer/Advice/Articles/BoilerMaintenance/BoilerMaintenanceArticle";
import EnergyEfficientDevices from "./pages/customer/Advice/Articles/EnergyEfficientDevices/EnergyEfficientDevices";
import ThermostatUseArticle from "./pages/customer/Advice/Articles/ThermostatUse/ThermostatUseArticle";
import ShutdownElectronicsArticle from "./pages/customer/Advice/Articles/ShutdownElectronics/ShutdownElectronicsArticle";
import HouseIsolationArticle from "./pages/customer/Advice/Articles/HouseIsolation/HouseIsolationArticle";
import { useEffect, useState } from "react";
import DocumentFill from "./pages/customer/ChangeEnergyContractCustomer/ChangeEnergyContractCustomer/DocumentFill/DocumentFill";
import DocumentCheck from "./pages/customer/ChangeEnergyContractCustomer/ChangeEnergyContractCustomer/DocumentCheck/DocumentCheck";
import DetailUserAddress from "./pages/administrator/Users/UserAddress/DetailUserAddress/DetailUserAddress";
import EditUserAddress from "./pages/administrator/Users/UserAddress/EditUserAddress/EditUserAddress";
import AddUserAddress from "./pages/administrator/Users/UserAddress/AddUserAddress/AddUserAddress";
import AddContractCombi from "./pages/administrator/Contracts/AddContract/AddContractCombi";
import EditContractCombi from "./pages/administrator/Contracts/EditContract/EditContractCombi";
import AddUserAddressCustomer from "./pages/customer/MyProfile/AddUserAddressCustomer/AddUserAddressCustomer";
import EditUserAddressCustomer from "./pages/customer/MyProfile/EditUserAddressCustomer/EditUserAddressCustomer";
import QuotesSuccesPage from "./pages/customer/Quotes/QuotesSuccessPage/QuotesSuccessPage";
import PasswordForgottenExpired from "./pages/authentication/PasswordForgotten/PasswordForgottenexpired/PasswordForgottenExpired";
import PasswordForgottenConfirm from "./pages/authentication/PasswordForgotten/PasswordForgottenConfirm/PasswordForgottenConfirm";
import PasswordForgottenReset from "./pages/authentication/PasswordForgotten/PasswordForgottenReset/PasswordForgottenReset";
import IndexData from "./pages/customer/Advice/indexData/IndexData";
import FluviusConnect from "./pages/customer/Fluvius/FluviusConnect";
import ElectricityContractInputPro from "./pages/customer/ChangeEnergyContractCustomer/ElectricityContractInput/ElectricityContractInputPro";
import CombiContractInputPro from "./pages/customer/ChangeEnergyContractCustomer/CombiContractInput/CombiContractInputPro";
import GasContractInputPro from "./pages/customer/ChangeEnergyContractCustomer/GasContractInput/GasContractInputPro";
import AddContractsElectricity from "./pages/customer/MyContracts/AddContracts/AddContractsElectricity";
import AddContractsGas from "./pages/customer/MyContracts/AddContracts/AddContractsGas";
import AircoApplicationCustomer from "./pages/customer/AircoApplicationCustomer/AircoApplicationCustomer";
import ChargingStationApplicationCustomer from "./pages/customer/ChargingStationApplicationCustomer/ChargingStationApplicationCustomer";
import EmsApplicationCustomer from "./pages/customer/EmsApplicationCustomer/EmsApplicationCustomer";
import HeatPumpApplicationCustomer from "./pages/customer/HeatPumpApplicationCustomer/HeatPumpApplicationCustomer";
import HeatPumpBoilerApplicationCustomer from "./pages/customer/HeatPumpBoilerApplicationCustomer/HeatPumpBoilerApplicationCustomer";
import InfraredPanelApplicationCustomer from "./pages/customer/InfraredPanelApplicationCustomer/InfraredPanelApplicationCustomer";
import IsolationApplicationCustomer from "./pages/customer/IsolationApplicationCustomer/IsolationApplicationCustomer";
import IsolationApplicationDetail from "./pages/administrator/IsolationApplication/IsolationApplicationDetail/IsolationApplicationDetail";
import ChargingStationApplication from "./pages/administrator/ChargingStationApplication/ChargingStationApplication";
import ChargingStationApplicationDetail from "./pages/administrator/ChargingStationApplication/ChargingStationApplicationDetail/ChargingStationApplicationDetail";
import HeatPumpApplication from "./pages/administrator/HeatPumpApplication/HeatPumpApplication";
import HeatPumpApplicationDetail from "./pages/administrator/HeatPumpApplication/HeatPumpApplicationDetail/HeatPumpApplicationDetail";
import HeatPumpBoilerApplication from "./pages/administrator/HeatPumpBoilerApplication/HeatPumpBoilerApplication";
import AircoApplication from "./pages/administrator/AircoApplication/AircoApplication";
import AircoApplicationDetail from "./pages/administrator/AircoApplication/AircoApplicationDetail/AircoApplicationDetail";
import EmsApplication from "./pages/administrator/EmsApplication/EmsApplication";
import EmsApplicationDetail from "./pages/administrator/EmsApplication/EmsApplicationDetail/EmsApplicationDetail";
import InfraredPanelApplication from "./pages/administrator/InfraredPanelApplication/InfraredPanelApplication";
import InfraredPanelApplicationDetail from "./pages/administrator/InfraredPanelApplication/InfraredPanelApplicationDetail/InfraredPanelApplicationDetail";
import IsolationApplication from "./pages/administrator/IsolationApplication/IsolationApplication";
import HeatPumpBoilerApplicationDetail from "./pages/administrator/HeatPumpBoilerApplication/HeatPumpBoilerApplicationDetail/HeatPumpBoilerApplicationDetail";
import ProfilePasswordReset from "./pages/customer/MyProfile/ProfilePasswordReset/ProfilePasswordReset";
import MarketPrice from "./pages/administrator/MarketPrice/MarketPrice";
import { getCookie } from "./cookieUtils";
import PricesAndAbbos from "./pages/customer/PricesAndAbbos/PricesAndAbbos";
import ContractOverview from "./pages/customer/ChangeEnergyContractCustomer/ContractOverview";

const App: React.FC = () => {
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/PasswordForgotten" element={<PasswordForgotten />} />
        <Route
          path="/PasswordForgottenExpired"
          element={<PasswordForgottenExpired />}
        />
        <Route
          path="/PasswordForgottenConfirm"
          element={<PasswordForgottenConfirm />}
        />
        <Route
          path="/PasswordForgottenReset/:passwordrequestId"
          element={<PasswordForgottenReset />}
        />

        <Route path="/IndexAdministrator" element={<IndexAdministrator />} />
        <Route path="/Users" element={<Users />} />
        <Route path="/DetailUser/:userId" element={<DetailUser />} />
        <Route path="/EditUser/:userId" element={<EditUser />} />
        <Route path="/AddUser/" element={<AddUser />} />
        <Route
          path="/DetailUserAddress/:userAddressId"
          element={<DetailUserAddress />}
        />
        <Route
          path="/EditUserAddress/:userAddressId"
          element={<EditUserAddress />}
        />
        <Route path="/AddUserAddress/:userId" element={<AddUserAddress />} />
        <Route path="/marketPrice" element={<MarketPrice />} />

        <Route path="/EnergySuppliers/" element={<EnergySuppliers />} />
        <Route
          path="/DetailEnergySupplier/:energySupplierId"
          element={<DetailEnergySupplier />}
        />
        <Route
          path="/EditEnergySupplier/:energySupplierId"
          element={<EditEnergySupplier />}
        />
        <Route path="/AddEnergySupplier/" element={<AddEnergySupplier />} />
        <Route path="/Contracts/" element={<Contracts />} />
        <Route
          path="/DetailContract/:contractId"
          element={<DetailContract />}
        />
        <Route path="/EditContract/:contractId" element={<EditContract />} />
        <Route
          path="/EditGasContract/:contractId"
          element={<EditContractGas />}
        />
        <Route
          path="/EditCombiContract/:contractId"
          element={<EditContractCombi />}
        />
        <Route path="/AddContract/" element={<AddContract />} />
        <Route path="/AddGasContract/" element={<AddContractGas />} />
        <Route path="/AddContractCombi/" element={<AddContractCombi />} />
        <Route
          path="/ChangeEnergyContract/"
          element={<ChangeEnergyContract />}
        />
        <Route
          path="/ChangeEnergyContractDetail/:id"
          element={<ChangeEnergyContractDetail />}
        />
        <Route
          path="/DetailContractInstance/:contractInstanceId"
          element={<DetailContractInstance />}
        />
        <Route
          path="/SolarPanelsApplication/"
          element={<SolarPanelsApplication />}
        />
        <Route
          path="/SolarPanelsApplicationDetail/:id"
          element={<SolarPanelsApplicationDetail />}
        />
        <Route
          path="/SolarPanelCleaningApplication/"
          element={<SolarPanelCleaningApplication />}
        />
        <Route
          path="/SolarPanelCleaningApplicationDetail/:id"
          element={<SolarPanelCleaningApplicationDetail />}
        />
        <Route
          path="/BoilerMaintenanceApplication/"
          element={<BoilerMaintenanceApplication />}
        />
        <Route
          path="/BoilerMaintenanceApplicationDetail/:id"
          element={<BoilerMaintenanceApplicationDetail />}
        />

        <Route
          path="/IsolationApplication/"
          element={<IsolationApplication />}
        />
        <Route
          path="/IsolationApplicationDetail/:id"
          element={<IsolationApplicationDetail />}
        />
        <Route
          path="/ChargingStationApplication/"
          element={<ChargingStationApplication />}
        />
        <Route
          path="/ChargingStationApplicationDetail/:id"
          element={<ChargingStationApplicationDetail />}
        />
        <Route path="/HeatPumpApplication/" element={<HeatPumpApplication />} />
        <Route
          path="/HeatPumpApplicationDetail/:id"
          element={<HeatPumpApplicationDetail />}
        />
        <Route
          path="/HeatPumpBoilerApplication/"
          element={<HeatPumpBoilerApplication />}
        />
        <Route
          path="/HeatPumpBoilerApplicationDetail/:id"
          element={<HeatPumpBoilerApplicationDetail />}
        />
        <Route path="/AircoApplication/" element={<AircoApplication />} />
        <Route
          path="/AircoApplicationDetail/:id"
          element={<AircoApplicationDetail />}
        />
        <Route path="/EmsApplication/" element={<EmsApplication />} />
        <Route
          path="/EmsApplicationDetail/:id"
          element={<EmsApplicationDetail />}
        />
        <Route
          path="/InfraredPanelApplication/"
          element={<InfraredPanelApplication />}
        />
        <Route
          path="/InfraredPanelApplicationDetail/:id"
          element={<InfraredPanelApplicationDetail />}
        />

        <Route path="/IndexCustomer" element={<IndexCustomer />} />
        <Route path="/FilesCustomer/" element={<FilesCustomer />} />
        <Route
          path="/ProfilePasswordReset/"
          element={<ProfilePasswordReset />}
        />
        <Route
          path="/SolarPanelsApplicationCustomer/"
          element={<SolarPanelsApplicationCustomer />}
        />
        <Route
          path="/SolarPanelCleaningApplicationCustomer/"
          element={<SolarPanelCleaningApplicationCustomer />}
        />
        <Route
          path="/BoilerMaintenanceApplicationCustomer/"
          element={<BoilerMaintenanceApplicationCustomer />}
        />
        <Route
          path="/AircoApplicationCustomer/"
          element={<AircoApplicationCustomer />}
        />
        <Route
          path="/ChargingStationApplicationCustomer/"
          element={<ChargingStationApplicationCustomer />}
        />
        <Route
          path="/EmsApplicationCustomer/"
          element={<EmsApplicationCustomer />}
        />
        <Route
          path="/HeatPumpApplicationCustomer/"
          element={<HeatPumpApplicationCustomer />}
        />
        <Route
          path="/HeatPumpBoilerApplicationCustomer/"
          element={<HeatPumpBoilerApplicationCustomer />}
        />
        <Route
          path="/InfraredPanelApplicationCustomer/"
          element={<InfraredPanelApplicationCustomer />}
        />
        <Route
          path="/IsolationApplicationCustomer/"
          element={<IsolationApplicationCustomer />}
        />
        <Route path="/Partners/" element={<Partners />} />
        <Route path="/PricesAndAbbos/" element={<PricesAndAbbos />} />
        <Route path="/Quotes/" element={<Quotes />} />
        <Route path="/Services/" element={<Services />} />
        <Route path="/Contact/" element={<Contact />} />
        <Route path="/MyContracts/" element={<MyContracts />} />
        <Route
          path="/MyContractsDetail/:contractInstanceId"
          element={<MyContractsDetail />}
        />
        <Route
          path="/ChangeEnergyContractCustomer/"
          element={<ChangeEnergyContractCustomer />}
        />
        <Route
          path="/ContractOverview/:energySource/:tarif/:isPro"
          element={<ContractOverview />}
        />
        <Route
          path="/ElectricityContractInput/"
          element={<ElectricityContractInput />}
        />
        <Route path="/GasContractInput/" element={<GasContractInput />} />
        <Route path="/CombiContractInput/" element={<CombiContractInput />} />
        <Route
          path="/ElectricityContractInputPro/"
          element={<ElectricityContractInputPro />}
        />
        <Route path="/GasContractInputPro/" element={<GasContractInputPro />} />
        <Route
          path="/CombiContractInputPro/"
          element={<CombiContractInputPro />}
        />
        <Route
          path="/AddContractsElectricity/"
          element={<AddContractsElectricity />}
        />
        <Route path="/AddContractsGas/" element={<AddContractsGas />} />

        <Route path="/DocumentSign/" element={<DocumentSign />} />
        <Route path="/DocumentCommit/" element={<DocumentCommit />} />
        <Route
          path="/DocumentFill/:documentTemplateId"
          element={<DocumentFill />}
        />
        <Route path="/DocumentCheck/" element={<DocumentCheck />} />
        <Route path="/Advice/" element={<Advice />} />
        <Route path="/MyProfile/" element={<MyProfile />} />
        <Route
          path="/AddUserAddressCustomer/:userId"
          element={<AddUserAddressCustomer />}
        />
        <Route
          path="/EditUserAddressCustomer/:userAddressId"
          element={<EditUserAddressCustomer />}
        />
        <Route path="/QuotesSuccessPage/" element={<QuotesSuccesPage />} />
        <Route path="/IndexData/" element={<IndexData />} />
        <Route path="/FluviusConnect/" element={<FluviusConnect />} />

        <Route
          path="/waarom-zijn-zonnepanelen-voordelig/"
          element={<SolarPanelsArticle />}
        />
        <Route
          path="/waarom-is-het-belangrijk-om-zonnepanelen-te-reinigen/"
          element={<SolarPanelsCleaningArticle />}
        />
        <Route
          path="/waarom-is-het-belangrijk-om-ketelonderhoud-uit-te-voeren/"
          element={<BoilerMaintenanceArticle />}
        />
        <Route
          path="/energie-zuinige-apparaten/"
          element={<EnergyEfficientDevices />}
        />
        <Route
          path="/thermostaat-gebruik/"
          element={<ThermostatUseArticle />}
        />
        <Route
          path="/electronische-apparaten-uitschakelen/"
          element={<ShutdownElectronicsArticle />}
        />
        <Route path="/huisisolatie/" element={<HouseIsolationArticle />} />
      </Routes>
    </Router>
  );
};

export default App;
